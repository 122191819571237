const awsConfig = {
    // identityPoolId: 'ap-south-1:62c69269-14c1-4213-9fd8-3011bcd1833c',
    // region: 'ap-south-1',
    // userPoolId: 'ap-south-1_lYRXJB955',
    // userPoolWebClientId: '2lflj5i9hesfe0cvqhofjopfpr'

//     "aws_project_region": "us-east-2",
//     "aws_cognito_identity_pool_id": "us-east-2:b0958948-9fee-4dce-9de2-fd7f03336d9e",
//     "aws_cognito_region": "us-east-2",
//    "aws_user_pools_id": "us-east-2_kItS2gWzv",
//     "aws_user_pools_web_client_id": "7dk0eveu85689tsjsvkb161kn",
//     "oauth": {},


    "aws_project_region": "us-east-2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": process.env.REACT_APP_AGENT_USER_POOL,
    "aws_user_pools_web_client_id": process.env.REACT_APP_aws_user_pools_web_client_id,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_aws_cognito_identity_pool_id,
    "oauth": {},
}

export default awsConfig;