import React, { Component } from 'react';
import ChatButton from './ChatButton';
export default class Footer extends Component {
    render() {
        return (
            <div style={{flexDirection:'row'}}>
                <div style={{float:'right',}}><ChatButton/></div>
                <div style={{position:'relative', top:65, left:10}}>
                    <div>Copyright © 2020 Universal Health Fellowship. All rights reserved.  |  Powered by CarynHealth</div>
                    <span>Version 3.4.1</span>
                </div>  
            </div>
        )
    }
}

