import React, { Component } from "react";
import './style.css'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { getQueryParams } from './utils';
import AgreeModal from './AgreeModal';
import { saveLogin, getPublicIP } from './utils';
import { Auth } from "aws-amplify";
import axios from 'axios';
import configurations from "../../configurations";
import Loader from './loader'

export class LaunchAgent extends Component {
    constructor(props) {
        super(props);
        this.emailInput = React.createRef();
        this.passwordInput = React.createRef();
        this.state = {
            agreeModal: this.props.agreeModal,
            errorMsg: ''
        }
    }

    componentDidMount() {
        let urlValues = window.location.hash ? window.location.hash.split('=') : [];
        if (urlValues && urlValues.length > 0) {
            if (urlValues[1]) {
                this.getDecodeData(urlValues[1]);
            }
        }
    }

    toggleLoader = (value) => {
        this.setState({
            showLoader: value
        })
    }

    getDecodeData = (value) => {
        this.toggleLoader(true)
        axios.get(configurations.baseUrl + '/encrypt/decryptData?state=' + value)
            .then(response => {
                if (response.data.response) {
                    sessionStorage.setItem('STATE_PARAM', JSON.stringify(response.data.response));
                    this.setState({
                        agentEmail: response.data.response.agentEmail,
                        agentName: response.data.response.agentName,
                        associationId: response.data.response.associationId,
                        brokerId: response.data.response.brokerId,
                        clientId: response.data.response.clientId,
                        agentPhone: response.data.response.agentPhone,
                        showLoader: false
                    }, () => this.handleSubmit())
                }
            });
    }

    handleSubmit = async (event) => {
        console.log('submit')
        this.toggleLoader(true)
                localStorage.setItem("launchAgent", true)
                saveLogin(this.state.agentEmail)
                sessionStorage.setItem('isLogged', false);
                this.props.history.push('/')
    }

    render() {
        return (
            <div className="NetWellPortalLoginDesktop">
                <Loader showLoader={this.state.showLoader} />
            </div>
        )
    }
}
export default LaunchAgent;
